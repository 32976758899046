// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  loginRestaurant,
  loginAdmin,
  logout as logoutService,
} from "../services/authService";
import { useNavigate } from "react-router-dom";
import { useLoading } from "./LoadingContext";
import {
  useThemeContext,
  defaultPrimaryColor,
  defaultSecondaryColor,
  defaultTertiaryColor,
} from "../theme/ThemeProvider";
import { getToken, isTokenValid, getUserRole } from "../utils/tokenUtils";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const initialState = {
  restaurantLogo: "HoloMenu",
  splashScreenBg: "#f5f5f5",
  themeBg: "",
  language: { language_id: 0, language_name: "English" },
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ token: null, role: null, user: null });
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => getToken() !== null
  );
  const [restaurantLogo, setRestaurantLogo] = useState(
    initialState.restaurantLogo
  ); // Default logo
  const [splashScreenBg, setSplashScreenBg] = useState(
    initialState.splashScreenBg
  );
  const [themeBg, setThemeBg] = useState(initialState.themeBg);

  // Language-related states
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    initialState.language
  );

  const navigate = useNavigate();
  const { startLoading, stopLoading } = useLoading();
  const { updateTheme } = useThemeContext();

  useEffect(() => {
    const token = getToken();
    const storedLanguage = localStorage.getItem("selectedLanguage");

    if (storedLanguage) {
      setSelectedLanguage(JSON.parse(storedLanguage));
    }

    if (token && isTokenValid()) {
      const decodedToken = jwtDecode(token);
      setAuth({ token, role: getUserRole(), user: decodedToken });
      setRestaurantTheme(decodedToken);
      setRestaurantLanguages(decodedToken);
      setIsAuthenticated(true);
    } else {
      logoutService();
      setAuth({ token: null, role: null, user: null });
      setLanguages([]);
      setSelectedLanguage(initialState.language);
      setIsAuthenticated(false);
      resetToDefaultTheme();
      navigate("/login");
    }
  }, []);

  const resetToDefaultTheme = () => {
    updateTheme(
      defaultPrimaryColor,
      defaultSecondaryColor,
      defaultTertiaryColor
    );
    setRestaurantLogo(initialState.restaurantLogo);
    setSplashScreenBg(initialState.splashScreenBg);
    setThemeBg(initialState.themeBg);
  };

  const setRestaurantLanguages = (decodedToken) => {
    const serverLanguages = decodedToken.restaurant.languages || [];
    const updatedLanguages = [...serverLanguages];
    setLanguages(updatedLanguages);
  };

  const setRestaurantTheme = (decodedToken) => {
    const { restaurant } = decodedToken;
    const { theme } = restaurant || {};

    // Extract theme colors and URLs
    const { primary_color, secondary_color, tertiary_color, font_url } =
      theme || {};

    setRestaurantLogo(restaurant.logo || initialState.restaurantLogo);
    setSplashScreenBg(theme?.splash_screen_bg || initialState.splashScreenBg);
    setThemeBg(theme?.theme_bg || initialState.themeBg);

    // Set default font URL for the theme, if available
    const defaultFontUrl = font_url || null;

    // Update MUI theme with colors and default font URL
    updateTheme(primary_color, secondary_color, tertiary_color, defaultFontUrl);
  };

  const changeLanguage = (language) => {
    const selectedLang =
      language || languages.find((lang) => lang.language_name === "English");

    setSelectedLanguage(selectedLang);

    // Save the selected language to local storage
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLang));

    // Extract current theme from auth.user
    const theme = auth?.user?.restaurant?.theme;

    // Determine font URL based on language
    let fontUrl = theme?.font_url; // Default font (English)
    if (selectedLang.language_name !== "English" && theme?.fonts) {
      const matchingFont = theme.fonts.find(
        (font) => font.language === selectedLang.language_id
      );
      fontUrl = matchingFont ? matchingFont.font_url : fontUrl;
    }

    // Update theme with new font URL, keeping colors unchanged
    const { primary_color, secondary_color, tertiary_color } = theme || {};
    updateTheme(primary_color, secondary_color, tertiary_color, fontUrl);
  };

  const loginUser = async (restaurantName) => {
    startLoading();
    try {
      const response = await loginRestaurant(restaurantName);
      const token = response?.data?.token;
      if (token) {
        localStorage.setItem("token", token);
        const decodedToken = jwtDecode(token);
        setRestaurantTheme(decodedToken);
        setAuth({ token, role: "user", user: decodedToken });
        setIsAuthenticated(true);
        setRestaurantLanguages(decodedToken);
        navigate("/startup");
      }
    } catch (error) {
      throw error;
    } finally {
      stopLoading();
    }
  };

  const loginAdminUser = async (username, password) => {
    startLoading();
    try {
      const response = await loginAdmin(username, password);
      const token = response?.data?.token;
      if (token) {
        localStorage.setItem("token", token);
        const decodedToken = jwtDecode(token);
        setRestaurantTheme(decodedToken);
        setAuth({ token, role: decodedToken.role, user: decodedToken });
        setIsAuthenticated(true);
        setRestaurantLanguages(decodedToken);
        navigate("/dashboard");
      }
    } catch (error) {
      throw error;
    } finally {
      stopLoading();
    }
  };

  const logout = () => {
    startLoading();
    setTimeout(() => {
      logoutService();
      resetToDefaultTheme();
      setAuth({ token: null, role: null, user: null });
      setIsAuthenticated(false);
      setLanguages([]);
      setSelectedLanguage(initialState.language);
      navigate("/login");
      stopLoading();
    }, 1000);
  };

  const value = {
    auth,
    isAuthenticated,
    loginUser,
    loginAdminUser,
    logout,
    restaurantLogo,
    splashScreenBg,
    themeBg,
    languages,
    selectedLanguage,
    changeLanguage,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
