import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Box, CircularProgress, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import DeleteIcon from "@mui/icons-material/Delete";
import { uploadFile as uploadMedia } from "../services/fileService";
import DeleteIcon from "../assets/icons/Trash.svg";

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {props.value > 0 ? `${Math.round(props.value)}%` : "Uploading..."}
        </Typography>
      </Box>
    </Box>
  );
};

const ImageUpload = ({
  imageUrl,
  onImageUpload,
  disabled,
  sxBox = {},
  sxImg = {},
  uploadLabel = "Upload a file",
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  const cachedImageUrl = useRef(imageUrl);
  const [imageLoaded, setImageLoaded] = useState(false);

  const validImageTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/webp",
  ];
  const validVideoTypes = ["video/mp4", "video/quicktime"];

  useEffect(() => {
    if (imageUrl && !imageLoaded) {
      cachedImageUrl.current = imageUrl;
      setImageLoaded(true);
    }
  }, [imageUrl, imageLoaded]);

  useEffect(() => {
    if (imageUrl !== cachedImageUrl.current) {
      setImageLoaded(false);
    }
  }, [imageUrl]);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (![...validImageTypes, ...validVideoTypes].includes(file.type)) {
      setError(
        "Invalid file type. Please upload a valid image (JPG, PNG, GIF, WEBP) or video (MP4, MOV)."
      );
      return;
    }

    if (file) {
      setIsUploading(true);
      setUploadProgress(0);
      setError(null);

      try {
        const uploadedUrl = await uploadMedia(file, (progress) => {
          setUploadProgress(progress);
        });
        cachedImageUrl.current = uploadedUrl;
        onImageUpload(uploadedUrl);
      } catch (err) {
        setError("Failed to upload file");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleDeleteImage = (event) => {
    event.stopPropagation();
    cachedImageUrl.current = null;
    onImageUpload(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*,video/*",
    onDrop,
    disabled,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: cachedImageUrl.current ? "none" : "2px dashed",
        borderColor: cachedImageUrl.current ? "none" : "divider",
        textAlign: "center",
        cursor: "pointer",
        mb: 3,
        opacity: disabled ? 0.5 : 1,
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 150,
        position: "relative",
        ...sxBox,
      }}
    >
      <input {...getInputProps()} disabled={disabled} />
      {isUploading ? (
        <CircularProgressWithLabel value={uploadProgress} />
      ) : cachedImageUrl.current ? (
        <>
          {validImageTypes.some((type) =>
            cachedImageUrl.current.includes(type.split("/")[1])
          ) ? (
            <Box
              component="img"
              src={cachedImageUrl.current}
              alt="Uploaded"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 1,
                ...sxImg,
              }}
            />
          ) : (
            <Box
              component="video"
              src={cachedImageUrl.current}
              autoPlay
              muted
              loop
              playsInline
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 1,
                ...sxImg,
              }}
            />
          )}
          <IconButton
            onClick={handleDeleteImage}
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              color: "red",
            }}
          >
            <img src={DeleteIcon} alt="My Icon" width={20} height={20} />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton sx={{ backgroundColor: "#4caf50", color: "white" }}>
            <AddIcon />
          </IconButton>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {isDragActive ? "Drop the file here..." : uploadLabel}
          </Typography>
        </>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default ImageUpload;
