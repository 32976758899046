import React from "react";

const CartIconComponent = ({ color = "white", width = 60, height = 60 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.917 5.16702C18.7619 3.65292 17.4613 2.5 15.9083 2.5H6.52382C4.8542 2.5 3.5 3.82649 3.5 5.46286C3.5 7.09924 4.8542 8.42573 6.52382 8.42573H13.1729L14.1871 18.3253C14.1867 18.3947 14.1904 18.464 14.1973 18.5334L16.1415 37.4755C16.1452 37.5084 16.1492 37.5413 16.1542 37.5737C16.3628 38.9219 17.0616 40.1506 18.122 41.0337C19.1792 41.914 20.5244 42.3914 21.9113 42.379H45.5026C46.741 42.4303 47.9634 42.0892 48.9891 41.4057C50.0311 40.7118 50.8126 39.7024 51.2139 38.5322L51.216 38.5262L56.2085 23.8141L56.2097 23.8093C56.4961 22.9594 56.5718 22.0547 56.4314 21.1699C56.2915 20.2888 55.9409 19.4522 55.4078 18.729C54.8498 17.9621 54.1052 17.344 53.2411 16.9303C52.3893 16.5226 51.4483 16.3274 50.5016 16.3606H20.0641L18.917 5.16702ZM49.4825 52.8466C49.4825 50.277 47.3563 48.1937 44.7338 48.1937C42.1109 48.1937 39.9847 50.277 39.9847 52.8466C39.9847 55.4163 42.1109 57.5 44.7338 57.5C47.3563 57.5 49.4825 55.4167 49.4825 52.8466ZM23.9864 48.1937C26.6089 48.1937 28.7351 50.277 28.7351 52.8466C28.7351 55.4163 26.6089 57.5 23.9864 57.5C21.3635 57.5 19.2373 55.4167 19.2373 52.8466C19.2373 50.2766 21.3635 48.1937 23.9864 48.1937Z"
        fill={color} // Dynamic color
      />
    </svg>
  );
};

export default CartIconComponent;
