import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import DeleteIcon from "@mui/icons-material/Delete";
import { useCart } from "../contexts/CartContext";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useAuth } from "../contexts/AuthContext";
import { useCurrency } from "../contexts/CurrencyContext";
import { useTheme } from "@mui/material/styles";
import LazyImage from "./lazyImage";
import DeleteIcon from "../assets/icons/Trash.svg";

const CartDialog = ({ open, onClose }) => {
  const { cartItems, updateQuantity, removeFromCart, clearCart } = useCart();
  const { selectedLanguage } = useAuth();
  const { currency } = useCurrency();
  const theme = useTheme();

  // Calculate total amount
  const totalAmount = cartItems.reduce(
    (total, item) => total + item.variants?.price * item.quantity,
    0
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": { height: "90svh" },
      }} // Set dialog height to 80vh
    >
      <DialogTitle>
        <DialogHeader onClose={onClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          p: { xs: 1, md: 2 },
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            height: "6px", // Horizontal scrollbar height
            width: "6px",
            borderRadius: "18px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "18px",
            height: "6px", // Horizontal scrollbar height
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "18px",
            backgroundColor: theme.palette.secondary.main,
            height: "6px", // Horizontal scrollbar height
            width: "6px",
          },
        }}
      >
        <CartItems
          cartItems={cartItems}
          removeFromCart={removeFromCart}
          updateQuantity={updateQuantity}
          selectedLanguage={selectedLanguage}
          currency={currency}
        />

        <Box>
          {cartItems.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
              }}
            >
              <TotalAmountCart totalAmount={totalAmount} currency={currency} />
              <EmptyCartButton clearCart={clearCart} />
            </Box>
          )}
          <HealthPriority />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CartDialog;

const HealthPriority = () => {
  return (
    <Box>
      <Typography fontSize="10px">Your safety is our top priority</Typography>
      <Typography fontSize="10px" sx={{ mt: 2 }}>
        We have analyzed our recipes and listed the allergens contained in each
        item on our menu. However, taking into consideration all possibilities
        of cross-contamination in our restaurants, we cannot be certain that any
        of our foods or beverages are "completely allergen free". Our
        high-quality food products are prepared in a kitchen that uses - but not
        limited to - crustaceans, celery, egg, fish, gluten, milk, mustard,
        peanuts, sesame, soya, and nuts. If you have any allergy or dietary
        requirement, it is your responsibility as our esteemed guest to notify
        one of our servers before placing your order. Our qualified staff can
        ensure with our duty of care, we exceed your needs and expectations for
        a pleasurable dining experience. The menu also includes the nutritional
        values associated with each food or beverage, single portion excluding
        sides/garnishes. Please keep in mind that the nutritional information is
        provided as a courtesy and is an estimate, based on standard recipes, to
        be used as a guide only.
      </Typography>
    </Box>
  );
};

const EmptyCartButton = ({ clearCart }) => {
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={clearCart}
      startIcon={<img src={DeleteIcon} alt="My Icon" width={18} height={18} />}
      sx={{ alignSelf: { xs: "flex-end", md: "none" } }}
    >
      Empty Cart
    </Button>
  );
};

const TotalAmountCart = ({ totalAmount, currency }) => {
  return (
    <Typography
      variant="h6"
      fontWeight="bold"
      sx={{ alignSelf: { xs: "flex-start", md: "none" } }}
    >
      Total: {totalAmount.toFixed(2)} {currency?.prefix}
    </Typography>
  );
};

const CartItems = ({
  cartItems,
  removeFromCart,
  updateQuantity,
  selectedLanguage,
  currency,
}) => {
  const deleteCartItemButton = (id) => {
    return (
      <>
        {/* For md and up */}
        <Box sx={{ display: { xs: "none", md: "unset" } }}>
          <IconButton
            onClick={() => removeFromCart(id)}
            sx={{
              color: "red",
              border: "1px solid #F4F4F5",
              borderRadius: "6px",
            }}
          >
            <img src={DeleteIcon} alt="My Icon" width={18} height={18} />
          </IconButton>
        </Box>

        {/* For xs only */}
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            display: { xs: "block", md: "none" },
          }}
        >
          <IconButton
            onClick={() => removeFromCart(id)}
            sx={{
              color: "red",
              border: "1px solid #F4F4F5",
              borderRadius: "6px",
            }}
          >
            <img src={DeleteIcon} alt="My Icon" width={18} height={18} />
          </IconButton>
        </Box>
      </>
    );
  };
  return (
    <Box>
      {cartItems.length > 0 ? (
        cartItems.map((item) => {
          // Find the translation for the item's name and menu based on the selected language
          const translatedItem = item.languages?.find(
            (lang) => lang.language_id === selectedLanguage.language_id
          );

          const translatedItemName = translatedItem?.name || item.name;
          const translatedMenuName =
            item.menus[0]?.languages?.find(
              (lang) => lang.language_id === selectedLanguage.language_id
            )?.name ||
            item.menus[0]?.name ||
            "Menu";

          return (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px",
                border: "1px solid #D4D4D8",
                borderRadius: "10px",
                mb: 3,
                height: { xs: "auto", md: "94px" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <LazyImage
                  src={item.image}
                  alt={translatedItemName}
                  sx={{
                    width: "93px",
                    height: "93px",
                    borderRadius: "8px",
                    marginRight: "16px",
                    objectFit: "cover",
                  }}
                />
                <Box sx={{ position: "relative", width: "100%" }}>
                  <Typography variant="body1" fontWeight="bold">
                    {translatedItemName}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {translatedMenuName}
                  </Typography>
                  <Typography variant="body1" color="black">
                    {item.variants?.price?.toFixed(2)} {currency?.prefix}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <IconButton
                      onClick={() => removeFromCart(item.id)}
                      sx={{
                        color: "red",
                        border: "1px solid #F4F4F5",
                        borderRadius: "6px",
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt="My Icon"
                        width={18}
                        height={18}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: { xs: "100%", md: "auto" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: { xs: "unset", md: "flex-end" },
                }}
              >
                <Box sx={{ display: { xs: "none", md: "unset" } }}>
                  <IconButton
                    onClick={() => removeFromCart(item.id)}
                    sx={{
                      color: "red",
                      border: "1px solid #F4F4F5",
                      borderRadius: "6px",
                    }}
                  >
                    <img
                      src={DeleteIcon}
                      alt="My Icon"
                      width={18}
                      height={18}
                    />
                  </IconButton>
                </Box>
                <ButtonGroup
                  variant="contained"
                  aria-label="Basic button group"
                  sx={{ mt: { xs: 1, md: "unset" } }}
                >
                  <Button
                    sx={{ borderRadius: "8px" }}
                    fullWidth
                    onClick={() =>
                      updateQuantity(item.id, Math.max(item.quantity - 1, 1))
                    }
                  >
                    <RemoveIcon />
                  </Button>
                  <Button fullWidth>
                    <Typography variant="body1">{item.quantity}</Typography>
                  </Button>
                  <Button
                    fullWidth
                    sx={{ borderRadius: "8px" }}
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                  >
                    <AddIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          );
        })
      ) : (
        <Typography variant="body2" sx={{ textAlign: "center", mt: 4 }}>
          Your cart is empty.
        </Typography>
      )}
    </Box>
  );
};

const DialogHeader = ({ onClose }) => {
  return (
    <>
      <Typography variant="h4" color="primary">
        Cart
      </Typography>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          color: "gray",
          position: "absolute",
          right: 18,
          top: 18,
          width: 30,
          height: 30,
          borderRadius: "7px",
        }}
      >
        <CloseIcon sx={{ width: 15 }} />
      </IconButton>
    </>
  );
};
