import { useRef, useState, useLayoutEffect } from "react";
import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../../contexts/AuthContext";
import MenuSkeletons from "../../../components/MenuSkeletons";
import EditButton from "../../../components/EditButton";
import MenuForm from "../../../components/forms/MenuForm";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useCrud from "../../../hooks/useCrud";

const ItemTypes = { MENU: "menu" };

const MenuListAdmin = ({ menus, activeMenu, handleMenuClick }) => {
  const { selectedLanguage } = useAuth();
  const theme = useTheme();
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { reorderData } = useCrud("/menus");
  const isLoading = menus.length === 0;

  const [isMenuFormOpen, setIsMenuFormOpen] = useState(false);
  const [editingMenuId, setEditingMenuId] = useState(null);
  const [menuOrder, setMenuOrder] = useState(menus);

  useLayoutEffect(() => {
    setMenuOrder(menus);
  }, [menus]);

  const updateArrowVisibility = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 30);
    }
  };

  useLayoutEffect(() => {
    const container = scrollContainerRef.current;
    const handleUpdate = () => updateArrowVisibility();

    // Delay to ensure accurate measurements after rendering
    setTimeout(() => {
      updateArrowVisibility();
    }, 0);

    container.addEventListener("scroll", handleUpdate);
    window.addEventListener("resize", handleUpdate);

    return () => {
      container.removeEventListener("scroll", handleUpdate);
      window.removeEventListener("resize", handleUpdate);
    };
  }, [menus]);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -900, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 900, behavior: "smooth" });
  };

  const handleOpenMenuForm = (menuId = null) => {
    setEditingMenuId(menuId);
    setIsMenuFormOpen(true);
  };

  const handleCloseMenuForm = () => {
    setIsMenuFormOpen(false);
    setEditingMenuId(null);
  };

  const handleSaveMenu = (menuData) => {
    console.log("Menu data to save:", menuData);
  };

  const moveMenuItem = (dragIndex, hoverIndex) => {
    const updatedMenus = [...menuOrder];
    const [draggedItem] = updatedMenus.splice(dragIndex, 1);
    updatedMenus.splice(hoverIndex, 0, draggedItem);
    setMenuOrder(updatedMenus);
  };

  const handleDrop = async () => {
    const reorderedItems = menuOrder.map((menu, index) => ({
      id: menu.id,
      position: index + 1,
    }));

    try {
      await reorderData(reorderedItems);
    } catch (error) {
      console.error("Reordering failed:", error);
      setMenuOrder(menus);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          width: "100%",
          overflow: "hidden",
          borderBottom: `1px solid ${theme.palette.secondary.main}`,
        }}
      >
        {showLeftArrow && (
          <ScrollArrow direction="left" onClick={scrollLeft} theme={theme} />
        )}
        <Box
          ref={scrollContainerRef}
          sx={{
            display: "flex",
            overflowX: "auto",
            width: "100%",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{
              borderRadius: 0,
              minWidth: "152px",
              color: "white",
              borderColor: "white",
              boxSizing: "border-box",
              marginRight: 2,
            }}
            onClick={() => handleOpenMenuForm(null)}
          >
            Add menu
          </Button>
          <Box
            sx={{
              position: "relative",
              px: "20px",
              cursor: "pointer",
              fontSize: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: { xs: "46px", md: "46px", lg: "46px" },
              backgroundColor:
                activeMenu === 0 ? theme.palette.secondary.main : "none",
              color: activeMenu === 0 ? theme.palette.primary.main : "white",
              fontWeight: activeMenu === 0 ? "bold" : "normal",
              borderBottom:
                activeMenu === 0
                  ? `1px solid ${theme.palette.secondary.main}`
                  : "none",
              whiteSpace: "nowrap",
            }}
            onClick={() => handleMenuClick(0)}
          >
            Unused Categories
          </Box>
          <MenuForm
            open={isMenuFormOpen}
            onClose={handleCloseMenuForm}
            onSave={handleSaveMenu}
            menuId={editingMenuId}
          />
          {isLoading ? (
            <MenuSkeletons />
          ) : (
            menuOrder.map((menu, index) => (
              <MenuItemAdmin
                key={menu.id}
                menu={menu}
                index={index}
                isActive={menu.id === activeMenu}
                theme={theme}
                onEdit={handleOpenMenuForm}
                selectedLanguage={selectedLanguage}
                onClick={() => {
                  handleMenuClick(menu.id);
                }}
                moveMenuItem={moveMenuItem}
                handleDrop={handleDrop}
              />
            ))
          )}
        </Box>
        {showRightArrow && (
          <ScrollArrow direction="right" onClick={scrollRight} theme={theme} />
        )}
      </Box>
    </DndProvider>
  );
};

const MenuItemAdmin = ({
  menu,
  index,
  isActive,
  onClick,
  theme,
  onEdit,
  selectedLanguage,
  moveMenuItem,
  handleDrop,
}) => {
  const ref = useRef(null);
  const translatedName =
    menu.languages?.find(
      (lang) =>
        Number(lang.language_id) === Number(selectedLanguage.language_id)
    )?.name || menu.name;

  const [, drop] = useDrop({
    accept: ItemTypes.MENU,
    hover(item) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveMenuItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: handleDrop,
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.MENU,
    item: { id: menu.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      sx={{
        opacity: isDragging || !menu.active ? 0.5 : 1,
        position: "relative",
        px: "40px",
        cursor: "pointer",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "46px", md: "46px", lg: "46px" },
        backgroundColor: isActive ? theme.palette.secondary.main : "none",
        color: isActive ? theme.palette.primary.main : "white",
        fontWeight: isActive ? "bold" : "normal",
        borderBottom: isActive
          ? `1px solid ${theme.palette.secondary.main}`
          : "none",
        whiteSpace: "nowrap",
      }}
      onClick={onClick}
    >
      {translatedName}
      <EditButton onClick={() => onEdit(menu.id)} />
    </Box>
  );
};

const ScrollArrow = ({ direction, onClick, theme }) => {
  const isLeft = direction === "left";
  const Icon = isLeft ? ArrowBackIosIcon : ArrowForwardIosIcon;
  return (
    <Icon
      onClick={onClick}
      sx={{
        [isLeft ? "left" : "right"]: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: `${theme.palette.secondary.main}`,
        transition: "opacity 0.9s ease-in-out",
        opacity: 1,
      }}
    />
  );
};

export default MenuListAdmin;
