import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import StatisticsChart from "./StatisticsChart";
import StatisticsToolbar from "./StatisticsToolbar";
import TableToolbar from "./TableToolbar";
import TableData from "./TableData";
import HeaderAnalytics from "./HeaderAnalytics";
import { useAnalytics } from "../../../contexts/AnalyticsContext";

const Analytics = () => {
  const { params, setParams, loading, analytics } = useAnalytics();
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [chartData, setChartData] = useState([]);

  const handleTabChange = (tab) => {
    setParams((prev) => ({ ...prev, activityType: tab }));
  };

  useEffect(() => {
    // Filter Data
    let data = analytics.filter((item) =>
      Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );

    // Sort Data
    if (sortColumn) {
      data.sort((a, b) => {
        if (typeof a[sortColumn] === "number") {
          return a[sortColumn] - b[sortColumn];
        } else if (typeof a[sortColumn] === "string") {
          return a[sortColumn].localeCompare(b[sortColumn]);
        } else if (sortColumn === "active") {
          return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? -1 : 1;
        }
        return 0;
      });
    }

    setFilteredData(data);

    const topClicks = formatClicksForChart(analytics);
    const topTimeSpent = formatTimeSpentForChart(analytics);
    const topOrders = formatOrdersForChart(analytics);
    setChartData([topClicks, topTimeSpent, topOrders]);
  }, [analytics, searchText, sortColumn]);

  const handleDateRangeChange = (newDateRange) => {
    setParams((prev) => ({
      ...prev,
      startDate: newDateRange.startDate,
      endDate: newDateRange.endDate,
    }));
  };

  return (
    <Box
      sx={{ padding: "20px", height: "calc(100vh - 40px)", overflow: "auto" }}
    >
      {/* Top Row: Go Back Button and Tab Buttons */}
      <HeaderAnalytics
        activeTab={params.activityType}
        handleTabChange={handleTabChange}
      />

      {/* Second Row: Title, Search, Dropdown, and Date Range */}
      <StatisticsToolbar
        title="Statistics"
        onDateRangeChange={handleDateRangeChange}
        rangeDate={{
          startDate: params.startDate,
          endDate: params.endDate,
          key: "selection",
        }}
        handleDateRangeChange={handleDateRangeChange}
      />

      {/* Third Row: Analytics Charts */}
      <StatisticsChart data={chartData} />

      {/* Filters for the Analytics Section */}
      <TableToolbar
        title={
          params.activityType.charAt(0).toUpperCase() +
          params.activityType.slice(1).toLowerCase()
        }
        activeCount={filteredData.length}
        onSearchChange={(value) => setSearchText(value)}
        onDropdownChange={(value) => setSortColumn(value)}
      />

      <TableData data={filteredData} />
    </Box>
  );
};

export default Analytics;

const formatTimeSpentForChart = (data) => {
  const parsedData = data.map((item) => ({
    ...item,
    total_time_spent: parseFloat(item.total_time_spent || 0), // Keep time in seconds for now
  }));

  // Total seconds for all items
  const totalSeconds = parsedData.reduce(
    (sum, item) => sum + item.total_time_spent,
    0
  );

  // Determine if the total is displayed as hours or minutes
  const displayTotal =
    totalSeconds >= 3600
      ? `${(totalSeconds / 3600).toFixed(2)} h` // Hours if total >= 1 hour
      : `${Math.round(totalSeconds / 60)} min`; // Minutes if total < 1 hour

  // Filter top 5 items with the highest time spent
  const sortedData = [...parsedData]
    .filter((item) => item.total_time_spent > 0) // Exclude items with 0 time spent
    .sort((a, b) => b.total_time_spent - a.total_time_spent)
    .slice(0, 5);

  // Calculate total time spent in seconds for the top 5
  const totalTopSeconds = sortedData.reduce(
    (sum, item) => sum + item.total_time_spent,
    0
  );

  return {
    minLabel: "0",
    maxLabel: displayTotal, // Use formatted total
    midLabel: "Top time spent",
    items: sortedData.map((item, index) => {
      const itemTime =
        item.total_time_spent >= 3600
          ? `${(item.total_time_spent / 3600).toFixed(2)} h` // Format as hours
          : `${Math.round(item.total_time_spent / 60)} min`; // Format as minutes

      return {
        percentage: ((item.total_time_spent / totalTopSeconds) * 100).toFixed(
          2
        ),
        label: `${item.name} (${itemTime})`, // Include time format
        color: index === 0 ? "#48664e" : "#EA8C26",
      };
    }),
  };
};

const formatLargeNumber = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k"; // Convert to 'k' format, remove unnecessary '.0'
  }
  return num.toString(); // If less than 1000, return as is
};

const formatClicksForChart = (data) => {
  const parsedData = data.map((item) => ({
    ...item,
    total_clicks: parseInt(item.total_clicks || 0, 10),
  }));

  const total = parsedData.reduce((sum, item) => sum + item.total_clicks, 0);

  const topFive = parsedData
    .filter((item) => item.total_clicks > 0)
    .sort((a, b) => b.total_clicks - a.total_clicks)
    .slice(0, 5);

  return {
    minLabel: "0",
    maxLabel: formatLargeNumber(total), // Format total with 'k' if needed
    midLabel: "Top clicks",
    items: topFive.map((item, index) => ({
      percentage: ((item.total_clicks / total) * 100).toFixed(2),
      label: item.name,
      color: index === 0 ? "#48664e" : "#EA8C26",
    })),
  };
};

const formatOrdersForChart = (data) => {
  const parsedData = data.map((item) => ({
    ...item,
    total_orders: parseInt(item.total_orders || 0, 10),
  }));

  const total = parsedData.reduce((sum, item) => sum + item.total_orders, 0);

  const topFive = parsedData
    .filter((item) => item.total_orders > 0)
    .sort((a, b) => b.total_orders - a.total_orders)
    .slice(0, 5);

  return {
    minLabel: "0",
    maxLabel: formatLargeNumber(total), // Format total with 'k' if needed
    midLabel: "Top orders",
    items: topFive.map((item, index) => ({
      percentage: ((item.total_orders / total) * 100).toFixed(2),
      label: item.name,
      color: index === 0 ? "#48664e" : "#EA8C26",
    })),
  };
};
