import React, { createContext, useState, useEffect, useContext } from "react";
import { getMenus } from "../services/menuService";
import { getItemsByMenuId } from "../services/itemService";
import { useLoading } from "./LoadingContext";
import { extractUniqueCategories } from "../utils/categoryUtils";
import { filterItemsByCategory as filterItemsByCategoryUtil } from "../utils/itemUtils";

export const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [menus, setMenus] = useState([]);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const { startLoading, stopLoading, isLoading } = useLoading();

  useEffect(() => {
    const fetchMenus = async () => {
      startLoading();
      try {
        const response = await getMenus();
        setMenus(response.data);
        if (response.data.length > 0) {
          setActiveMenu(response.data[0].id);
          const items = await fetchItems(response.data[0].id);
          setItems(items);
          setUniqueCategories(items);
        }
      } catch (error) {
        setError("Failed to load menus");
      } finally {
        stopLoading();
      }
    };

    const fetchItems = async (id) => {
      try {
        const response = await getItemsByMenuId(id);
        setItems(response.data);
        return response.data;
      } catch (error) {
        setError("Failed to load items");
      }
    };

    fetchMenus();
  }, []);

  const setUniqueCategories = (items) => {
    const uniqueCategories = extractUniqueCategories(items);
    if (uniqueCategories.length > 0) {
      setCategories(uniqueCategories);
      setSelectedCategory(uniqueCategories[0]);
      filterItemsByCategory(items, uniqueCategories[0].id);
    } else {
      setCategories([]);
      setSelectedCategory(null);
      setFilteredItems([]);
      console.log("No unique categories found.");
    }
  };

  const filterItemsByCategory = (itemsToFilter = items, categoryId) => {
    const filtered = filterItemsByCategoryUtil(itemsToFilter, categoryId);
    setFilteredItems(filtered);
  };

  const handleMenuClick = async (id) => {
    setActiveMenu(id);
    startLoading();
    try {
      const { data: items } = await getItemsByMenuId(id);
      setItems(items);
      setUniqueCategories(items);
      //   filterItemsByCategory(data);
    } catch (error) {
      setError("Failed to load items");
    } finally {
      stopLoading();
    }
  };

  const handleCategoryClick = (id) => {
    const selected = categories.find((category) => category.id === id);
    if (selected) {
      setSelectedCategory(selected);
      filterItemsByCategory(items, id);
    }
  };

  return (
    <MenuContext.Provider
      value={{
        menus,
        items,
        filteredItems,
        categories,
        activeMenu,
        selectedCategory,
        handleMenuClick,
        handleCategoryClick,
        error,
        isLoading,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
