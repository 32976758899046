import {
  Box,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  Slide,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import { useTheme } from "@mui/material/styles";
import { isImageUrl } from "../../utils/isImageUrl";
import { useAuth } from "../../contexts/AuthContext";
import { useCurrency } from "../../contexts/CurrencyContext";
import LazyImage from "../../components/lazyImage";
import { useDashboard } from "../../contexts/DashboardProvider";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import CopyDialog from "../../components/CopyDialog";
import DeleteIcon from "../../assets/icons/TrashWhite.svg";
import EditIcon from "../../assets/icons/Edit.svg";
import CopyIcon from "../../assets/icons/copy.svg";

const ItemDetails = ({
  selectedCategory,
  selectedItem,
  handleCloseOverlay,
  height,
  isOpen,
  handleOpenItemForm,
}) => {
  const theme = useTheme();
  const [showVideo, setShowVideo] = useState(false);
  const { themeBg } = useAuth();

  const handleToggleMedia = () => {
    if (selectedItem.video) {
      setShowVideo(!showVideo);
    }
  };

  return (
    <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: { xs: "100svh", md: `${height}svh` },
          width: "100%",
          backgroundColor: theme.palette.secondary.main,
          backgroundImage: isImageUrl(themeBg) ? `url(${themeBg})` : "none",
          zIndex: 10,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
        }}
      >
        <Box sx={{ flex: { xs: 0, md: 3 }, height: { xs: "35%", md: "100%" } }}>
          <ItemMedia
            handleCloseOverlay={handleCloseOverlay}
            handleToggleMedia={handleToggleMedia}
            selectedCategory={selectedCategory}
            selectedItem={selectedItem}
            showVideo={showVideo}
            handleOpenItemForm={handleOpenItemForm}
          />
        </Box>
        <Box
          sx={{
            height: { xs: "65%", md: "100%" },
            paddingX: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box sx={{ height: "50%" }}>
            <ItemPrice price={selectedItem.variants.price} />
            <NameDescriptionItems item={selectedItem} />
          </Box>

          <Box
            sx={{
              height: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "4px",
            }}
          >
            <Allergens allergies={selectedItem.allergies} />
            <Variants variants={selectedItem.variants} />
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};

export default React.memo(ItemDetails);

const ItemPrice = React.memo(({ price }) => {
  const { currency } = useCurrency();

  return (
    <Typography
      variant="h6"
      sx={{ fontWeight: "bold", mt: 2, mb: 1, color: "black" }}
    >
      {price?.toFixed(2) || "--.--"} {currency?.prefix}
    </Typography>
  );
});

const NameDescriptionItems = ({ item }) => {
  // Fetch the selected language from AuthContext
  const { selectedLanguage } = useAuth();
  const theme = useTheme();

  // Find the translation that matches the selected language
  const translatedItem = item.languages?.find(
    (lang) => lang.language_id === selectedLanguage.language_id
  );

  const translatedItemName = translatedItem?.name || item.name;
  const translatedItemDescription =
    translatedItem?.description || item.description;

  return (
    <Box sx={{ height: "100%" }}>
      <Typography
        variant="h4"
        color="primary"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        {translatedItemName}
      </Typography>
      <Box
        sx={{
          maxHeight: { xs: "50%", md: "60%", lg: "70%" }, // Set a fixed max-height for the description box
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            height: "6px", // Horizontal scrollbar height
            width: "6px",
            borderRadius: "20px",
            marginLeft: 1,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
            height: "6px", // Horizontal scrollbar height
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "20px",
            backgroundColor: theme.palette.secondary.main,
            height: "6px", // Horizontal scrollbar height
            width: "6px",
          },
        }}
      >
        <Typography variant="body2" gutterBottom>
          {translatedItemDescription}
        </Typography>
      </Box>
    </Box>
  );
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 75, // Increased width
  height: 48, // Increased height
  padding: 12, // Increased padding for larger thumb and track
  "& .MuiSwitch-switchBase": {
    margin: 13, // Adjusted margin for spacing
    padding: 0,
    transform: "translateX(0px)", // Adjusted for larger switch
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(25px)", // Adjusted for larger switch
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 24" fill="none"><path d="M14.7295 2H9.26953V6.36H14.7295V2Z" fill="%233F3F46"/><path d="M16.2305 2V6.36H21.8705C21.3605 3.61 19.3305 2.01 16.2305 2Z" fill="%233F3F46"/><path d="M2 7.86035V16.1904C2 19.8304 4.17 22.0004 7.81 22.0004H16.19C19.83 22.0004 22 19.8304 22 16.1904V7.86035H2ZM14.44 16.1804L12.36 17.3804C11.92 17.6304 11.49 17.7604 11.09 17.7604C10.79 17.7604 10.52 17.6904 10.27 17.5504C9.69 17.2204 9.37 16.5404 9.37 15.6604V13.2604C9.37 12.3804 9.69 11.7004 10.27 11.3704C10.85 11.0304 11.59 11.0904 12.36 11.5404L14.44 12.7404C15.21 13.1804 15.63 13.8004 15.63 14.4704C15.63 15.1404 15.2 15.7304 14.44 16.1804Z" fill="%233F3F46"/><path d="M7.77086 2C4.67086 2.01 2.64086 3.61 2.13086 6.36H7.77086V2Z" fill="%233F3F46"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#000",
        ...theme.applyStyles("light", {
          backgroundColor: "#16a34a",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    position: "absolute",
    backgroundColor: "#001e3c",
    width: 24, // Increased thumb size
    height: 24, // Increased thumb size
    top: 0,
    left: 0,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path d="M2.58078 19.0103L2.56078 19.0303C2.29078 18.4403 2.12078 17.7703 2.05078 17.0303C2.12078 17.7603 2.31078 18.4203 2.58078 19.0103Z" fill="%23A1A1AA"/><path d="M8.99914 10.3801C10.3136 10.3801 11.3791 9.31456 11.3791 8.00012C11.3791 6.68568 10.3136 5.62012 8.99914 5.62012C7.6847 5.62012 6.61914 6.68568 6.61914 8.00012C6.61914 9.31456 7.6847 10.3801 8.99914 10.3801Z" fill="%23A1A1AA"/><path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z" fill="%23A1A1AA"/></svg>')`,
    },
    ...theme.applyStyles("light", {
      backgroundColor: "#fff",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#a1a1aa",
    borderRadius: 50 / 2, // Adjusted for larger size
    border: "1px solid white",
    ...theme.applyStyles("secondary", {
      backgroundColor: "#a1a1aa",
    }),
  },
}));

const Variants = ({ variants }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "4px",
        marginTop: "8px",
      }}
    >
      <VariantBox variantName="Kcal" variantValue={variants?.calories} />
      <VariantBox variantName="Fat" variantValue={variants?.fat} />
      <VariantBox variantName="Carbs" variantValue={variants?.carbs} />
      <VariantBox variantName="Protien" variantValue={variants?.proteins} />
    </Box>
  );
};

const VariantBox = ({ variantName, variantValue }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "4px",
        flex: "1", // Allow the boxes to grow and take equal width
        // maxWidth: "150px", // Optionally set a max-width to control overflow
      }}
    >
      {/* The rectangular box for the variant value */}
      <Box
        sx={{
          border: `2px solid ${theme.palette.primary.main}`, // Border for the variant box
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px",
          gap: 1,
          width: "70%", // Let the width grow according to the flex container
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {variantValue || "-"}
        </Typography>
      </Box>

      {/* The variant name below the box */}
      <Typography
        variant="body2"
        sx={{ fontWeight: "normal", textAlign: "center" }}
      >
        {variantName}
      </Typography>
    </Box>
  );
};

const Allergens = ({ allergies }) => {
  if (allergies.length === 0 || allergies[0].id === null) return;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Add an icon before the Allergens title */}
        <NewReleasesRoundedIcon
          sx={{ width: "28px", height: "20px", marginRight: "8px" }}
        />
        <Typography variant="h6" color="black" sx={{ fontWeight: "bold" }}>
          Allergens:
        </Typography>
      </Box>

      <Typography variant="body1" color="black" gutterBottom>
        {allergies?.map((allergy) => allergy.name).join(", ")}
      </Typography>
    </>
  );
};

const ItemMedia = ({
  handleCloseOverlay,
  showVideo,
  handleToggleMedia,
  selectedCategory,
  selectedItem,
  handleOpenItemForm,
}) => {
  const {
    reloadData,
    itemsCrud: { deleteData: deleteItem, loading },
    itemsCategoryCrud: { createData: removeItemCategory, itemCategoryLoading },
  } = useDashboard();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [copyOpen, setCopyOpen] = useState(false);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const handleOpenCopyDialog = () => setCopyOpen(true);
  const handleCloseCopyDialog = () => setCopyOpen(false);

  const handleDelete = async () => {
    try {
      await deleteItem(selectedItem.id);
      setDialogOpen(false);
      reloadData();
      handleCloseOverlay();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = async () => {
    try {
      const removeBody = {
        item_id: selectedItem.id,
        category_id: selectedCategory.id,
      };
      await removeItemCategory(removeBody);
      setDialogOpen(false);
      reloadData();
      handleCloseOverlay();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
      {/* Overlay buttons */}
      <IconButton
        onClick={handleCloseOverlay}
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
          color: "white",
          background: "rgba(255,255,255,0.2)",
          zIndex: 2,
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box
        sx={{
          position: "absolute",
          top: 20,
          right: 20,
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <IconButton
          className="admin-btn"
          onClick={() => handleOpenItemForm(selectedItem.id)}
        >
          {/* <BorderColorRoundedIcon /> */}
          <img src={EditIcon} alt="My Icon" width={24} height={24} />
        </IconButton>
        <IconButton className="admin-btn" onClick={handleOpenCopyDialog}>
          <img src={CopyIcon} alt="My Icon" width={24} height={24} />
          {/* <ContentCopyRoundedIcon /> */}
        </IconButton>
        <IconButton className="admin-btn" onClick={handleOpenDialog}>
          <img src={DeleteIcon} alt="My Icon" width={24} height={24} />
          {/* <DeleteRoundedIcon /> */}
        </IconButton>
      </Box>
      <DeleteConfirmationDialog
        open={dialogOpen}
        itemName={selectedItem.name}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        onRemove={handleRemove}
        loading={loading}
        loadingRemove={itemCategoryLoading}
        categoryId={selectedCategory.id}
      />
      <CopyDialog
        open={copyOpen}
        onClose={handleCloseCopyDialog}
        item={selectedItem}
      />
      <FormControlLabel
        control={
          <MaterialUISwitch
            checked={showVideo}
            onChange={handleToggleMedia}
            sx={{ color: "white" }}
          />
        }
        label=""
        sx={{
          position: "absolute",
          bottom: 20,
          right: 0,
          zIndex: 2,
        }}
      />

      {/* Video or Image Content */}
      {showVideo && selectedItem?.video ? (
        <Box component="video" sx={mediaStyle} autoPlay muted loop playsInline>
          <source src={selectedItem.video} type="video/mp4" />
        </Box>
      ) : (
        <LazyImage
          src={selectedItem.image}
          alt={selectedItem.name}
          sx={mediaStyle}
        />
      )}
    </Box>
  );
};

const mediaStyle = {
  height: "100%",
  width: "100%",
  borderRadius: { xs: "0 0 15px 15px", md: "0 15px 15px 15px" },
  objectFit: "cover",
  backgroundColor: "#cccccc",
};
