import { Box, Typography } from "@mui/material";
import DateRangePicker from "../../../components/DateRangePicker";

const StatisticsToolbar = ({
  title = "Statistics",
  rangeDate,
  handleDateRangeChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
        gap: 1,
      }}
    >
      {/* Title */}
      <Typography variant="h4">{title}</Typography>

      {/* Date Range Picker */}
      <DateRangePicker
        direction={{ right: 0, top: 35 }}
        rangeDate={rangeDate}
        handleDateRangeChange={handleDateRangeChange}
      />
    </Box>
  );
};

export default StatisticsToolbar;
