import { Box, Typography, Button } from "@mui/material";
// import FavoriteIcon from "@mui/icons-material/Favorite"; // Icon for Feedback
// import StarIcon from "@mui/icons-material/Star"; // Icon for Follow us
import LogoutButton from "../../components/LogoutButton";
import { useTheme } from "@mui/material/styles";
import FavoriteIcon from "../../assets/icons/Feedback.svg";
import StarIcon from "../../assets/icons/Favourite.svg";

const ButtonsGroup = ({ handleOpenAdminLogin }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Stack vertically
        gap: 2, // Spacing between each item
        alignItems: "center", // Center items in the box
      }}
    >
      {/* Feedback and Follow us Buttons */}
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          startIcon={
            <img src={FavoriteIcon} alt="My Icon" width={24} height={24} />
          }
          sx={{
            padding: { xs: "5px 30px", md: "5px 50px" },
            fontSize: "1.125rem",
            height: "41px",
          }}
        >
          Feedback
        </Button>
        <Button
          variant="contained"
          startIcon={
            <img src={StarIcon} alt="My Icon" width={24} height={24} />
          }
          sx={{
            px: { xs: "30px", md: "50px" },
            fontSize: "1.125rem",
            height: "41px",
          }}
        >
          Follow us
        </Button>
      </Box>

      {/* Login as Admin */}
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.primary.main,
          cursor: "pointer",
          marginBottom: "8px",
          textDecoration: "underline",
        }}
        onClick={handleOpenAdminLogin}
      >
        Login as an admin
      </Typography>

      {/* Logout Button */}
      <LogoutButton />
    </Box>
  );
};

export default ButtonsGroup;
