import React, { useState } from "react";
import {
  Box,
  TextField,
  ClickAwayListener,
  InputAdornment,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs"; // Import dayjs for parsing and formatting
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

function TimeRangePicker({ handleTimeRangeChange, timeRange }) {
  const [showTimePicker, setShowTimePicker] = useState(false);

  // Ensure startTime and endTime are dayjs objects
  const startTime = timeRange.startTime
    ? dayjs(timeRange.startTime, "HH:mm:ss")
    : "";
  const endTime = timeRange.endTime ? dayjs(timeRange.endTime, "HH:mm:ss") : "";

  const formattedStartTime = startTime ? startTime.format("HH:mm") : "";
  const formattedEndTime = endTime ? endTime.format("HH:mm") : "";

  const handleClickAway = () => {
    setShowTimePicker(false);
  };

  const handleTextFieldClick = () => {
    setShowTimePicker((prev) => !prev);
  };

  const handleTimeChange = (time, key) => {
    handleTimeRangeChange(time, key);
  };

  return (
    <Box sx={{ position: "absolute" }}>
      <TextField
        variant="outlined"
        size="small"
        value={
          formattedStartTime && formattedEndTime
            ? `${formattedStartTime} - ${formattedEndTime}`
            : "HH:mm - HH:mm"
        }
        onClick={handleTextFieldClick}
        readOnly
        fullWidth
        sx={{
          backgroundColor: "#f5f5f5",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccessTimeIcon />
            </InputAdornment>
          ),
        }}
      />
      {showTimePicker && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: "absolute",
              zIndex: 100,
              left: 0,
              mt: 1,
              bottom: 0,
              boxShadow: 3,
              backgroundColor: "background.paper",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Start Time"
                value={startTime}
                onChange={(time) => handleTimeChange(time, "startTime")}
                renderInput={(params) => <TextField {...params} />}
                ampm={false} // 24-hour format
                views={["hours", "minutes"]}
              />
              <TimePicker
                label="End Time"
                value={endTime}
                onChange={(time) => handleTimeChange(time, "endTime")}
                renderInput={(params) => <TextField {...params} />}
                ampm={false} // 24-hour format
                views={["hours", "minutes"]}
              />
            </LocalizationProvider>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default TimeRangePicker;
