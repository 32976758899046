import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Button,
  Box,
  CircularProgress,
  Alert,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseDialogButton from "../CloseDialogButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ImportFileField from "../ImportFileField";
import { importData, exportData } from "../../services/transferService";
import { useDashboard } from "../../contexts/DashboardProvider";
import { useTheme } from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const FilesForm = ({ open, onClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const importFileRef = useRef(null);
  const { reloadData } = useDashboard();
  const theme = useTheme();

  useEffect(() => {
    resetFilesForm();
  }, [open]);

  const resetFilesForm = () => {
    setActiveTab(0);
    setUploadError(null);
    setUploadedFile(null);
    setSuccessMessage(null);
  };

  const handleTabChange = (event, newValue) => {
    if (!loading) setActiveTab(newValue);
  };

  const handleTransferData = async () => {
    if (activeTab === 0) {
      // Handle Import
      if (uploadedFile) {
        setLoading(true);
        try {
          await importData(uploadedFile.file, (progress) => {
            console.log("Upload progress:", progress);
          });
          setSuccessMessage("Data imported successfully!");
          setUploadError(null);
          setUploadedFile(null);
          console.log("before");
          importFileRef.current.removeFile();
          console.log("after");
          reloadData();
          console.log("kaka");
        } catch (error) {
          setUploadError("Error uploading file. Please try again.");
        } finally {
          setLoading(false);
        }
      }
    } else {
      // Handle Export
      setLoading(true);
      try {
        await exportData();
        console.log("Export successful");
      } catch (error) {
        console.error("Error exporting file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileUpload = (fileName, fileUrl, file) => {
    setUploadedFile(file ? { name: fileName, url: fileUrl, file } : null);
    setUploadError(null);
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>
        Files
        <CloseDialogButton onClose={onClose} loading={loading} />
      </DialogTitle>
      <DialogContent sx={{ minHeight: 200 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Import" sx={{ flex: 1 }} />
            <Tab label="Export" sx={{ flex: 1 }} />
          </Tabs>
        </Box>

        <Box marginTop={4}>
          {activeTab === 0 ? (
            <>
              <Alert severity="info" sx={{ mb: 2 }}>
                <Typography variant="h6">Note</Typography>
                <Typography variant="body1">
                  To import data for the first time, please upload excel within
                  sheets:
                </Typography>
                <Typography variant="body1">
                  <ul>
                    <li>Items</li>
                    <li>Menus</li>
                    <li>Categories</li>
                  </ul>
                </Typography>
                <Typography variant="body1">
                  If the category or menu does not exist in the portal, but in
                  the shoot. The item will not be uploaded
                </Typography>
              </Alert>
              <ImportFileField
                allowedExtensions={["xlsx", "xls"]}
                onFileUpload={handleFileUpload}
                fileName={uploadedFile}
                ref={importFileRef}
              />
              <Typography variant="body2" gutterBottom mt={1}>
                <a
                  href="/sample_import_data.xlsx"
                  download="sample_import_data.xlsx"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                  }}
                >
                  Download example
                </a>
              </Typography>
              {uploadError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {uploadError}
                </Alert>
              )}
              {successMessage && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  {successMessage}
                </Alert>
              )}
            </>
          ) : (
            <Alert severity="info" sx={{ mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Export Summary
              </Typography>
              <Typography variant="body1" gutterBottom>
                Your exported file will include the following sheets:
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Items"
                    secondary="Detailed list of individual items, including names, descriptions, prices, and related categories."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Menus"
                    secondary="Overview of all available menus with their names and descriptions, representing various offerings (e.g., breakfast, lunch)."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Categories"
                    secondary="Classification of items by type (e.g., appetizers, main courses) to help organize the menu items."
                  />
                </ListItem>
              </List>
              <Typography variant="body2">
                Each sheet is designed to organize data for easy reference and
                to support further analysis or adjustments as needed.
              </Typography>
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ flexDirection: "row-reverse", px: 3, mb: 2 }}>
        <Button
          onClick={handleTransferData}
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{ px: 4 }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : activeTab === 0 ? (
            "Submit"
          ) : (
            "Export"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilesForm;
