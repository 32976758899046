import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import CloseDialogButton from "./CloseDialogButton";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";

const DeleteConfirmationDialog = ({
  open,
  onClose,
  onDelete,
  onRemove,
  itemName,
  loading,
  loadingRemove,
  categoryId,
}) => {
  const [deleteFromAll, setDeleteFromAll] = useState(false);

  // Automatically set checkboxes if categoryId is null
  useEffect(() => {
    if (categoryId === null) {
      setDeleteFromAll(true); // Ensure "Delete from all categories" is checked
    }
  }, [categoryId]);

  const handleDelete = () => {
    if (deleteFromAll || categoryId === null) {
      onDelete(); // Use onDelete if categoryId is null or "Delete from all categories" is checked
    } else {
      onRemove();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="delete-dialog-title"
    >
      <div
        style={{ padding: "20px", textAlign: "center", position: "relative" }}
      >
        <DialogTitle>
          <CloseDialogButton
            onClose={onClose}
            loading={loading || loadingRemove}
          />
        </DialogTitle>

        <DialogContent>
          <NewReleasesRoundedIcon
            style={{ fontSize: "48px", marginBottom: 10 }}
          />
          <Typography variant="h4">
            Are you sure you want to delete "{itemName}"?
          </Typography>
          <p style={{ color: "#666", marginBottom: "20px" }}>
            If you delete the item, all settings will be gone.
          </p>
          <div
            style={{
              textAlign: "left", // Aligns checklist content to the left
              marginLeft: "24px", // Adds padding for proper alignment
            }}
          >
            <FormControlLabel
              control={<Checkbox checked disabled />}
              label="Delete from this category only."
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={deleteFromAll}
                  disabled={categoryId === null} // Disable if categoryId is null
                  onChange={(e) => setDeleteFromAll(e.target.checked)}
                />
              }
              label="Delete from all categories of this restaurant."
            />
          </div>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            disabled={loading || loadingRemove}
            style={{
              padding: "8px 32px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            disabled={loading || loadingRemove}
            style={{
              padding: "8px 32px",
              textTransform: "none",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            {loading || loadingRemove ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
