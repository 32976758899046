import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import {
  TextField,
  Box,
  ClickAwayListener,
  InputAdornment,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

function DateRangePicker({
  direction = { right: 0, bottom: 0 },
  rangeDate = { startDate: null, endDate: null, key: "selection" },
  handleDateRangeChange,
}) {
  const [showCalendar, setShowCalendar] = useState(false);

  const formattedStartDate = rangeDate.startDate
    ? format(rangeDate.startDate, "MM/dd/yyyy")
    : "";
  const formattedEndDate = rangeDate.endDate
    ? format(rangeDate.endDate, "MM/dd/yyyy")
    : "";

  const handleClickAway = () => {
    setShowCalendar(false);
  };

  const handleTextFieldClick = () => {
    setShowCalendar((prev) => !prev);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <TextField
        variant="outlined"
        size="small"
        value={
          formattedStartDate && formattedEndDate
            ? `${formattedStartDate} - ${formattedEndDate}`
            : "MM/dd/yyyy - MM/dd/yyyy"
        }
        onClick={handleTextFieldClick}
        readOnly
        fullWidth
        sx={{
          backgroundColor: "#f5f5f5",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          width: "230px",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarTodayIcon />
            </InputAdornment>
          ),
        }}
      />
      {showCalendar && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: "absolute",
              zIndex: 100,
              mt: 1,
              boxShadow: 3,
              backgroundColor: "background.paper",
              ...direction,
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => handleDateRangeChange(item.selection)}
              moveRangeOnFirstSelection={false}
              ranges={[rangeDate]}
            />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default DateRangePicker;
