import { useState } from "react";
import { Box, Typography, IconButton, Badge } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CartIconComponent from "../../assets/icons/CartIconComponent";
import { useTheme } from "@mui/material/styles";
import CartDialog from "../../components/CartDialog";
import { useAuth } from "../../contexts/AuthContext";
import { useCart } from "../../contexts/CartContext";
import { isImageUrl } from "../../utils/isImageUrl";
import LazyImage from "../../components/lazyImage";
import ItemSkeleton from "../../components/ItemSkeletons";

const ItemListUser = ({
  items,
  selectedCategory,
  dimensions,
  handleOpenOverlay,
  selectedItem,
}) => {
  const [cartOpen, setCartOpen] = useState(false);
  const theme = useTheme();
  const { themeBg } = useAuth();

  const handleOpenCart = () => {
    setCartOpen(true);
  };

  const handleCloseCart = () => {
    setCartOpen(false);
  };

  const correctDim = (
    padding = dimensions.itemListBoxPadding,
    screenSize = "md"
  ) => {
    const itemListWidth = `calc(100svw - ${padding * 2}px)`;
    const itemContainerWidth = `${
      dimensions.contentContainerWidth - padding
    }px`;
    let itemListHeight = "";
    if (screenSize === "md") {
      itemListHeight = 100 - dimensions.upperContentHeight + "svh";
    } else if (screenSize === "xs") {
      itemListHeight = `65svh`;
    }
    return { itemListWidth, itemContainerWidth, itemListHeight };
  };

  return (
    <Box
      sx={{
        width: { xs: correctDim().itemListWidth, md: "100svw" },
        height: {
          xs: selectedItem ? 0 : correctDim("1", "xs").itemListHeight,
          md: correctDim("1", "md").itemListHeight,
        },
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: isImageUrl(themeBg) ? `url(${themeBg})` : "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        px: { xs: `${dimensions.itemListBoxPadding}px`, md: 0 },
        zIndex: 40,
        transition: "height 0.3s ease-in-out", // Smooth height transition
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: { md: correctDim().itemContainerWidth },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "35px",
            }}
          >
            <SelectedCategoryName selectedCategory={selectedCategory} />
            <OpenCartDialogButton handleOpenCart={handleOpenCart} />
          </Box>
          <Items items={items} handleOpenOverlay={handleOpenOverlay} />
          <CartDialog open={cartOpen} onClose={handleCloseCart} />
        </Box>
      </Box>
    </Box>
  );
};

export default ItemListUser;

const SelectedCategoryName = ({ selectedCategory }) => {
  const { selectedLanguage } = useAuth();
  const theme = useTheme();

  const translatedName =
    selectedCategory?.languages?.find(
      (lang) => lang.language_id === selectedLanguage.language_id
    )?.name || selectedCategory?.name;
  return (
    <Typography
      variant="h4"
      sx={{
        color: theme.palette.primary.main,
        textOverflow: { xs: "ellipsis", md: "unset" }, // Add this
        overflow: { xs: "hidden", md: "unset" }, // Add this
        whiteSpace: { xs: "nowrap", md: "unset" },
      }}
    >
      {translatedName}
    </Typography>
  );
};

const OpenCartDialogButton = ({ handleOpenCart }) => {
  const { cartItems } = useCart();
  const theme = useTheme();
  return (
    <Badge
      badgeContent={cartItems?.length}
      color="primary"
      overlap="circular"
      sx={{
        "& .MuiBadge-badge": {
          top: 5,
          right: 5,
          color: "#fff",
        },
      }}
    >
      <IconButton
        // className="reverse"
        onClick={handleOpenCart}
        sx={{ padding: "7px", borderColor: theme.palette.primary.main }}
      >
        <CartIconComponent color={theme.palette.primary.main} />
      </IconButton>
    </Badge>
  );
};

const Items = ({ items, handleOpenOverlay }) => {
  const theme = useTheme();
  const isLoading = items.length === 0;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        overflowX: "auto",
        py: "5px",
        flexGrow: 1,
        "&::-webkit-scrollbar": {
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main,
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: theme.palette.secondary.main,
        },
      }}
    >
      {isLoading ? (
        <ItemSkeleton />
      ) : (
        items?.map((item) => (
          <Item
            key={item.id}
            item={item}
            handleOpenOverlay={handleOpenOverlay}
          />
        ))
      )}
    </Box>
  );
};

const Item = ({ item, handleOpenOverlay }) => {
  const { selectedLanguage } = useAuth();
  const theme = useTheme();

  const translatedName =
    item?.languages?.find(
      (lang) => lang.language_id === selectedLanguage.language_id
    )?.name || item?.name;

  return (
    <Box
      key={item.id}
      sx={{
        position: "relative",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      onClick={() => handleOpenOverlay(item)}
    >
      <Box
        sx={{
          width: { xs: "140px", md: "114px" },
          height: { xs: "140px", md: "114px" },
          borderRadius: "50%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `3px solid ${theme.palette.tertiary.main}`,
          backgroundColor: theme.palette.primary.main,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
      >
        <LazyImage
          src={item.image}
          alt={translatedName}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundColor: "#cccccc",
          }}
        />
      </Box>

      <Typography
        variant="body2"
        sx={{
          width: "114px",
          marginTop: "8px",
          fontSize: { xs: "14px", md: "12px" },
          textOverflow: { xs: "ellipsis", md: "unset" }, // Add this
          overflow: { xs: "hidden", md: "unset" }, // Add this
          whiteSpace: { xs: "nowrap", md: "unset" }, // Add this
        }}
      >
        {translatedName}
      </Typography>
    </Box>
  );
};
