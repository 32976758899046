import { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../contexts/AuthContext";
import { useActivity } from "../../contexts/ActivityContext";
import MenuSkeletons from "../../components/MenuSkeletons";

const MenuListUser = ({ menus, activeMenu, handleMenuClick }) => {
  const { selectedLanguage } = useAuth();
  const theme = useTheme();
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { trackMenu } = useActivity();
  const isLoading = menus.length === 0;

  const updateArrowVisibility = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 30);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    updateArrowVisibility();
    container.addEventListener("scroll", updateArrowVisibility);
    window.addEventListener("resize", updateArrowVisibility);
    return () => {
      container.removeEventListener("scroll", updateArrowVisibility);
      window.removeEventListener("resize", updateArrowVisibility);
    };
  }, [menus]);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -900, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 900, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      }}
    >
      {showLeftArrow && (
        <ScrollArrow direction="left" onClick={scrollLeft} theme={theme} />
      )}
      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          overflowX: "auto",
          width: "100%",
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
      >
        {isLoading ? (
          <MenuSkeletons />
        ) : (
          menus.map((menu, index) => (
            <MenuItemUser
              key={menu.id}
              menu={menu}
              index={index}
              isActive={menu.id === activeMenu}
              theme={theme}
              selectedLanguage={selectedLanguage}
              onClick={() => {
                handleMenuClick(menu.id);
                trackMenu(menu.id);
              }}
            />
          ))
        )}
      </Box>
      {showRightArrow && (
        <ScrollArrow direction="right" onClick={scrollRight} theme={theme} />
      )}
    </Box>
  );
};

const MenuItemUser = ({
  menu,
  index,
  isActive,
  onClick,
  theme,
  selectedLanguage,
}) => {
  const translatedName =
    menu.languages?.find(
      (lang) =>
        Number(lang.language_id) === Number(selectedLanguage.language_id)
    )?.name || menu.name;

  return (
    <Box
      sx={{
        position: "relative",
        px: "40px",
        cursor: "pointer",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "46px", md: "46px", lg: "46px" },
        backgroundColor: isActive ? theme.palette.secondary.main : "none",
        color: isActive ? theme.palette.primary.main : "white",
        fontWeight: isActive ? "bold" : "normal",
        borderBottom: isActive
          ? `1px solid ${theme.palette.secondary.main}`
          : "none",
        whiteSpace: "nowrap",
      }}
      onClick={onClick}
    >
      {translatedName}
    </Box>
  );
};

const ScrollArrow = ({ direction, onClick, theme }) => {
  const isLeft = direction === "left";
  const Icon = isLeft ? ArrowBackIosIcon : ArrowForwardIosIcon;
  return (
    <Icon
      onClick={onClick}
      sx={{
        [isLeft ? "left" : "right"]: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: `${theme.palette.secondary.main}`,
        transition: "opacity 0.9s ease-in-out",
        opacity: 1,
      }}
    />
  );
};

export default MenuListUser;
