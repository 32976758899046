import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  TextField,
  Button,
  Box,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
  Autocomplete,
  Chip,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../contexts/AuthContext";
import { useDashboard } from "../../contexts/DashboardProvider";
import ImageUpload from "../ImageUpload";
import CloseDialogButton from "../CloseDialogButton";
import DateRangePicker from "../DateRangePicker";
import TimeRangePicker from "../TimeRangePicker";
import DeleteIcon from "../../assets/icons/Trash.svg";

const initialState = (languages) => {
  return {
    name: "",
    description: "",
    image: "",
    video: "",
    preparation_time: "",
    categories: [],
    menus: [],
    allergies: [],
    schedule_date_from: null,
    schedule_date_to: null,
    schedule_time_from: null,
    schedule_time_to: null,
    variants: {
      price: "",
      calories: "",
      fat: "",
      carbs: "",
      proteins: "",
    },
    languages: languages.map((lang) => ({
      language_id: lang.language_id,
      name: "",
      description: "",
    })),
    active: true,
  };
};

const ItemForm = ({ open, onClose, onSave, itemId = null }) => {
  const { languages } = useAuth();
  const {
    reloadData,
    categoriesNames: categories,
    menus,
    allergies,
    itemsCrud: {
      createData,
      updateData,
      deleteData,
      getDataById,
      setActivationState,
      loading,
      error,
      fieldErrors,
    },
  } = useDashboard();
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({ ...initialState(languages) });
  const [originalName, setOriginalName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [dispalySchedual, setDispalySchedual] = useState(false);

  useEffect(() => {
    const loadItem = async () => {
      if (itemId) {
        try {
          const item = await getDataById(itemId);
          setOriginalName(item.name);
          const {
            restaurant_id,
            item_code,
            media_3d,
            updated_at,
            created_at,
            ...rest
          } = item;
          setItemCode(item_code);
          setFormData({
            ...rest,
            menus: item.menus.map((menu) => menu.id),
            categories: item.categories.map((cat) => cat.id),
            allergies: item.allergies.map((allergy) => allergy.id),
            variants: { ...item.variants },
            active: !!item.active,
          });
          if (
            item.schedule_date_from ||
            item.schedule_date_to ||
            item.schedule_time_from ||
            item.schedule_time_to
          )
            setDispalySchedual(true);
        } catch (error) {
          console.error("Error fetching item:", error);
        }
      } else {
        resetFormData();
      }
    };
    if (open) loadItem();
    else {
      resetFormData();
    }
  }, [itemId, open]);

  const resetFormData = () => {
    setFormData({ ...initialState(languages) });
    setOriginalName("");
    setDispalySchedual(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleTabChange = (event, newValue) => {
    if (!loading) setActiveTab(newValue);
  };

  const handleLanguageChange = (languageId, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      languages: prevData.languages.map((lang) =>
        lang.language_id === languageId ? { ...lang, [field]: value } : lang
      ),
    }));
  };

  const handleSave = async () => {
    try {
      const { active, id, ...data } = formData;
      const adjustedData = {
        ...data,
        languages: data.languages.map((lang) => ({
          language_id: lang.language_id,
          description: lang.description,
          name: lang.language_name,
        })),
      };
      console.log(adjustedData);

      if (itemId) {
        await updateData(itemId, adjustedData);
      } else {
        await createData(adjustedData);
      }
      onSave(formData);
      onClose();
      reloadData();
    } catch (err) {
      console.error("Error saving item:", err);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await deleteData(itemId);
        onClose();
        onSave(itemId);
        reloadData();
      } catch (err) {
        console.error("Error deleting item:", err);
      }
    }
  };

  const handleToggleActiveState = async (event) => {
    const newActiveState = event.target.checked;
    try {
      await setActivationState(itemId, newActiveState);
      setFormData((prevData) => ({ ...prevData, active: newActiveState }));
      reloadData();
    } catch (err) {
      console.error("Error updating active state:", err);
    }
  };

  const handleMenusChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      menus: value.map((menu) => menu.id),
    }));
  };

  const handleCategoriesChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      categories: value.map((category) => category.id),
    }));
  };

  const handleAllergiesChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      allergies: value.map((allergy) => allergy.id),
    }));
  };

  const handleVariantsChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      variants: { ...prevData.variants, [field]: value },
    }));
  };

  const handleDateRangeChange = (newDateRange) => {
    if (dispalySchedual) {
      setFormData((prev) => ({
        ...prev,
        schedule_date_from: newDateRange.startDate,
        schedule_date_to: newDateRange.endDate,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        schedule_date_from: null,
        schedule_date_to: null,
      }));
    }
  };

  const handleTimeRangeChange = (time, key) => {
    if (dispalySchedual) {
      if (key === "startTime")
        setFormData((prev) => ({ ...prev, schedule_time_from: time }));
      else if (key === "endTime")
        setFormData((prev) => ({ ...prev, schedule_time_to: time }));
    } else {
      setFormData((prev) => ({
        ...prev,
        schedule_time_from: null,
        schedule_time_to: null,
      }));
    }
  };

  const handleChangeSchedual = () => {
    if (dispalySchedual) {
      setFormData((prev) => ({
        ...prev,
        schedule_date_from: null,
        schedule_date_to: null,
        schedule_time_from: null,
        schedule_time_to: null,
      }));
    }
    setDispalySchedual((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>
        {itemId ? `Edit "${originalName}" Item` : "Add Item"}
        <CloseDialogButton loading={loading} onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        {itemId && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="caption" color="gray">
              <span style={{ color: "black" }}>Item code:</span> {itemCode}
            </Typography>
            <IconButton
              onClick={handleDelete}
              sx={{
                color: "red",
                border: "1px solid #F4F4F5",
                borderRadius: "6px",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={16} />
              ) : (
                <img src={DeleteIcon} alt="My Icon" width={20} height={20} />
              )}
            </IconButton>
          </Box>
        )}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="English" sx={{ flex: 1 }} />
            {languages.map((lang) => (
              <Tab
                key={lang.language_id}
                label={lang.language_name}
                sx={{ flex: 1 }}
              />
            ))}
          </Tabs>
        </Box>
        <Box marginTop={2}>
          {activeTab === 0 ? (
            <Grid container spacing={2}>
              <Grid container size={6}>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    label="Item Name"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    required
                    error={!!fieldErrors.name}
                    helperText={fieldErrors.name}
                    disabled={loading}
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    label="Price"
                    value={formData.variants.price}
                    onChange={(e) =>
                      handleVariantsChange("price", e.target.value)
                    }
                    margin="normal"
                    required
                    error={!!fieldErrors["variants.price"]}
                    helperText={fieldErrors["variants.price"]}
                    disabled={loading}
                  />
                  <TextField
                    fullWidth
                    label="Calories"
                    value={formData.variants.calories}
                    onChange={(e) =>
                      handleVariantsChange("calories", e.target.value)
                    }
                    margin="normal"
                    required
                    error={!!fieldErrors["variants.calories"]}
                    helperText={fieldErrors["variants.calories"]}
                    disabled={loading}
                  />
                  <TextField
                    fullWidth
                    label="Fat"
                    value={formData.variants.fat}
                    onChange={(e) =>
                      handleVariantsChange("fat", e.target.value)
                    }
                    margin="normal"
                    required
                    error={!!fieldErrors["variants.fat"]}
                    helperText={fieldErrors["variants.fat"]}
                    disabled={loading}
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    label="Preparation time"
                    value={formData.preparation_time}
                    onChange={(e) =>
                      handleChange("preparation_time", e.target.value)
                    }
                    margin="normal"
                    required
                    error={!!fieldErrors.preparation_time}
                    helperText={fieldErrors.preparation_time}
                    disabled={loading}
                  />
                  <TextField
                    fullWidth
                    label="Carbs"
                    value={formData.variants.carbs}
                    onChange={(e) =>
                      handleVariantsChange("carbs", e.target.value)
                    }
                    margin="normal"
                    required
                    error={!!fieldErrors["variants.carbs"]}
                    helperText={fieldErrors["variants.carbs"]}
                    disabled={loading}
                  />
                  <TextField
                    fullWidth
                    label="Proteins"
                    value={formData.variants.proteins}
                    onChange={(e) =>
                      handleVariantsChange("proteins", e.target.value)
                    }
                    margin="normal"
                    required
                    error={!!fieldErrors["variants.proteins"]}
                    helperText={fieldErrors["variants.proteins"]}
                    disabled={loading}
                  />
                </Grid>
                <Grid size={12}>
                  <Autocomplete
                    multiple
                    options={menus}
                    getOptionLabel={(menu) => menu.name || "Unnamed Menu"}
                    value={menus.filter((menu) =>
                      formData.menus.includes(menu.id)
                    )}
                    onChange={handleMenusChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.name || "Unnamed Menu"}
                          {...getTagProps({ index })}
                          key={option.id}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Menus"
                        placeholder="Choose menus"
                        error={!!fieldErrors.menus}
                        helperText={fieldErrors.menus}
                      />
                    )}
                    disabled={loading}
                  />
                </Grid>
                <Grid size={12}>
                  <Autocomplete
                    multiple
                    options={categories}
                    getOptionLabel={(category) =>
                      category.name || "Unnamed Category"
                    }
                    value={categories.filter((category) =>
                      formData.categories.includes(category.id)
                    )}
                    onChange={handleCategoriesChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.name || "Unnamed Category"}
                          {...getTagProps({ index })}
                          key={option.id}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Categories"
                        placeholder="Choose categories"
                        error={!!fieldErrors.categories}
                        helperText={fieldErrors.categories}
                      />
                    )}
                    disabled={loading}
                  />
                </Grid>
                <Grid>
                  {itemId && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.active}
                          onChange={handleToggleActiveState}
                          color="primary"
                        />
                      }
                      label="Active Status"
                      disabled={loading}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container size={6} height="100%">
                <Grid size={6}>
                  <ImageUpload
                    imageUrl={formData.image}
                    onImageUpload={(url) => handleChange("image", url)}
                    disabled={loading}
                    uploadLabel="svg, png Image"
                    sxBox={{ mb: 0 }}
                  />
                </Grid>
                <Grid size={6}>
                  <ImageUpload
                    imageUrl={formData.video}
                    onImageUpload={(url) => handleChange("video", url)}
                    disabled={loading}
                    uploadLabel="mp4, mov Video"
                    sxBox={{ mb: 0 }}
                  />
                </Grid>
                <Grid size={12}>
                  <Autocomplete
                    multiple
                    options={allergies}
                    getOptionLabel={(allergy) =>
                      allergy.name || "Unnamed Allergy"
                    }
                    value={allergies.filter((allergy) =>
                      formData.allergies.includes(allergy.id)
                    )}
                    onChange={handleAllergiesChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.name || "Unnamed Allergy"}
                          {...getTagProps({ index })}
                          key={option.id}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Allergies"
                        placeholder="Choose allergies"
                        error={!!fieldErrors.allergies}
                        helperText={fieldErrors.allergies}
                      />
                    )}
                    disabled={loading}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={formData.description}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                    margin="normal"
                    required
                    error={!!fieldErrors.description}
                    helperText={fieldErrors.description}
                    disabled={loading}
                  />
                </Grid>
                <Grid size={12}>
                  {itemId && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={dispalySchedual}
                          onChange={handleChangeSchedual}
                          color="primary"
                        />
                      }
                      label="Schedule Item"
                      disabled={loading}
                    />
                  )}
                </Grid>
                {dispalySchedual && (
                  <>
                    <Grid size={12}>
                      <DateRangePicker
                        rangeDate={{
                          startDate: formData.schedule_date_from,
                          endDate: formData.schedule_date_to,
                          key: "selection",
                        }}
                        handleDateRangeChange={handleDateRangeChange}
                      />
                    </Grid>
                    <Grid size={12}>
                      <TimeRangePicker
                        timeRange={{
                          startTime: formData.schedule_time_from || null,
                          endTime: formData.schedule_time_to || null,
                        }}
                        handleTimeRangeChange={handleTimeRangeChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              <TextField
                fullWidth
                label={`Name (${languages[activeTab - 1].language_name})`}
                value={formData.languages[activeTab - 1].name}
                onChange={(e) =>
                  handleLanguageChange(
                    languages[activeTab - 1].language_id,
                    "name",
                    e.target.value
                  )
                }
                margin="normal"
                disabled={loading}
              />
              <TextField
                fullWidth
                label={`Description (${
                  languages[activeTab - 1].language_name
                })`}
                value={formData.languages[activeTab - 1].description}
                onChange={(e) =>
                  handleLanguageChange(
                    languages[activeTab - 1].language_id,
                    "description",
                    e.target.value
                  )
                }
                margin="normal"
                disabled={loading}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            sx={{ px: 4, mx: 1 }}
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ px: 4 }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : itemId ? (
              "Update"
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ItemForm;
