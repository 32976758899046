import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
// import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import { IconButton } from "@mui/material";
import EditIcon from "../assets/icons/Edit.svg";

const EditButton = ({ onClick, sx = {} }) => {
  return (
    <IconButton
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        borderRadius: "50%",
        position: "absolute",
        right: 5,
        top: 7,
        height: "26px",
        width: "26px",
        zIndex: 10,
        border: "1.5px solid #fff",
        "&:hover": {
          backgroundColor: "#000",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <img src={EditIcon} alt="My Icon" width={12} height={12} />

      {/* <BorderColorRoundedIcon sx={{ height: "16px", width: "16px" }} /> */}
    </IconButton>
  );
};

export default EditButton;
