import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../contexts/AuthContext";
import { isImageUrl } from "../utils/isImageUrl";
import HolomenuTitle from "./holomenuTitle";
import LazyImage from "./lazyImage";
import SettingsForm from "./forms/SettingsForm";
import FilesForm from "./forms/FilesForm";
import Analytics from "../assets/icons/Analytics.svg";
import Settings from "../assets/icons/Settings.svg";
import LogoutIcon from "../assets/icons/logout.svg";
import LanguageIcon from "../assets/icons/Language.svg";
import HomeIcon from "../assets/icons/Home.svg";
import DocumentIcon from "../assets/icons/Document-text.svg";

const HeaderSection = (props) => {
  const { restaurantLogo, auth, logout } = useAuth();
  const [isSettingFormOpen, setIsSettingFormOpen] = useState(false);
  const [isFilesFormOpen, setIsFilesFormOpen] = useState(false);

  const isAdmin = auth.role !== "user";

  const handleToggglingOpenSettingForm = () =>
    setIsSettingFormOpen(!isSettingFormOpen);

  const handleTogglingOpenFilesForm = () => setIsFilesFormOpen((prev) => !prev);

  const renderRestaurantLogo = () => {
    const isLogoUrl = isImageUrl(restaurantLogo);
    if (isLogoUrl)
      return (
        <LazyImage
          src={restaurantLogo}
          alt={auth.user.restaurant.restaurant_name}
          sx={{ height: "34px" }}
        />
      );
    else
      return (
        <HolomenuTitle
          title={auth?.user?.restaurant?.restaurant_name}
          style={{
            height: "34px",
            fontSize: { xs: "16px", md: "34px" },
            mb: 0,
          }}
        />
      );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingY: 2,
        width: "100%",
      }}
    >
      {/* Left Side - Home Button */}
      <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-start" }}>
        <HomeButton isAdmin={isAdmin} />
      </Box>

      {/* Center - Logo */}
      <Box sx={{ display: "flex", justifyContent: "center", flex: 2 }}>
        {renderRestaurantLogo()}
      </Box>

      {/* Right Side - Language Dropdown IconButton */}
      <Box
        sx={{ flex: 1, display: "flex", justifyContent: "flex-end", gap: 1 }}
      >
        {isAdmin && (
          <>
            <FilesDataButton onClick={handleTogglingOpenFilesForm} />
            <AnaltyicsButton />
            <SettingsButton onClick={handleToggglingOpenSettingForm} />
            <SettingsForm
              open={isSettingFormOpen}
              onClose={handleToggglingOpenSettingForm}
            />
            <FilesForm
              open={isFilesFormOpen}
              onClose={handleTogglingOpenFilesForm}
            />
          </>
        )}
        <LanguageButton />
        {isAdmin && <LogoutButton onClick={logout} />}
      </Box>
    </Box>
  );
};

export default HeaderSection;

const FilesDataButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} className="admin-btn">
      <img src={DocumentIcon} alt="My Icon" width={24} height={24} />
      {/* <DescriptionRoundedIcon /> */}
    </IconButton>
  );
};

const AnaltyicsButton = () => {
  const navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate("/analytics")} className="admin-btn">
      <img src={Analytics} alt="My Icon" width={24} height={24} />
    </IconButton>
  );
};

const SettingsButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} className="admin-btn">
      <img src={Settings} alt="My Icon" width={24} height={24} />
    </IconButton>
  );
};

const LogoutButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} className="admin-btn">
      <img src={LogoutIcon} alt="My Icon" width={24} height={24} />
    </IconButton>
  );
};

const HomeButton = ({ isAdmin }) => {
  const navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate("/startup")} disabled={isAdmin}>
      <img src={HomeIcon} alt="My Icon" width={24} height={24} />
    </IconButton>
  );
};

const LanguageButton = ({}) => {
  const theme = useTheme();
  const { languages, selectedLanguage, changeLanguage } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = (language) => {
    setAnchorEl(null);
    changeLanguage(language);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleLanguageClick}
        color="inherit"
      >
        <img src={LanguageIcon} alt="My Icon" width={24} height={24} />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            width: "200px",
          },
        }}
      >
        <MenuItem
          key={0}
          selected={selectedLanguage?.language_id === 0}
          onClick={() =>
            handleLanguageChange({ language_id: 0, language_name: "English" })
          }
          sx={{
            typography: "body1",
            color:
              selectedLanguage?.language_id === 0
                ? theme.palette.primary.main
                : "inherit",
          }}
        >
          English
        </MenuItem>
        {languages.map((lang) => (
          <MenuItem
            key={lang.language_id}
            selected={selectedLanguage?.language_id === lang.language_id}
            onClick={() => handleLanguageChange(lang)}
            sx={{
              typography: "body1",
              color:
                selectedLanguage?.language_id === lang.language_id
                  ? theme.palette.primary.main
                  : "inherit",
            }}
          >
            {lang.language_name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
