export const extractUniqueCategories = (items) => {
  if (!Array.isArray(items) || items.length === 0) {
    console.log("No items to process.");
    return [];
  }

  const allCategories = items
    .filter(
      (item) => Array.isArray(item.categories) && item.categories.length > 0
    )
    .flatMap((item) => item.categories);

  const uniqueCategories = [...new Set(allCategories.map((cat) => cat.id))]
    .map((id) => {
      const category = allCategories.find((cat) => cat.id === id);
      if (category && category.id === null) {
        return {
          ...category,
          name: "Uncategorized", // Set the title for uncategorized items
        };
      }
      return category;
    })
    .sort((a, b) => {
      if (a.category_position != null && b.category_position != null) {
        return a.category_position - b.category_position;
      }
      return 0;
    });

  return uniqueCategories;
};
